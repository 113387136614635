import { ZodError, z } from "zod";
import { MagnifyingGlassIcon, PlusIcon } from "@heroicons/react/24/outline";
import { EmptyTableData, TableDataRows, TableError, TableLoading } from "./WebinarProgramTable";
import { Card } from "../../../components/card";
import { PaginationSchema, usePaginationStore } from "../../../store/pagePaginationStore";
import { Link } from "react-router-dom";
import { useState } from "react";
import { buildUrl } from "../../../util/buildUrl";
import useSWRImmutable from "swr/immutable";
import { defaultGetFetcher } from "../../../api/base";
import { TablePagination } from "../../../components/tables/tablePagination";

const WebinarProgramSchema = z.object({
  id: z.number(),
  title: z.string(),
  createdBy: z.string(),
  isActive: z.boolean(),
  isAssetManagementProgram: z.boolean(),
  createdOn: z.string().datetime(),
});

const WebinarProgramListSchema = z.object({
  programs: WebinarProgramSchema.array(),
  pagination: z.object(PaginationSchema.shape),
});

export type WebinarProgram = z.infer<typeof WebinarProgramSchema>;
export type WebinarProgramList = z.infer<typeof WebinarProgramListSchema>;

const WebinarPrograms = () => {
  const currentPage = usePaginationStore((state) => state.currentPage);
  const setPaginationData = usePaginationStore.getState().updatePaginationInfo;
  const [searchQuery, _] = useState<string | null>(null);

  const url = buildUrl("/api/v2/admin/webinar-programs", {
    pageNumber: currentPage,
    pageSize: 10,
    searchQuery: searchQuery,
  });
  const { data, isLoading, error } = useSWRImmutable<string, Error>(url, (url: string) => defaultGetFetcher(url), {
    revalidateOnMount: true,
  });
  const getTableData = () => {
    if (isLoading) return <TableLoading />;
    if (error) return <TableError errorMessage={error.message} />;
    try {
      const responseData: WebinarProgramList = WebinarProgramListSchema.parse(data!);
      setTimeout(() => setPaginationData(responseData.pagination));

      if (responseData.programs.length == 0) return <EmptyTableData />;

      return <TableDataRows programs={responseData.programs} />;
    } catch (parseError: unknown) {
      if (parseError instanceof ZodError) return <TableError errorMessage={parseError.message} />;
      else return <TableError errorMessage={String(parseError)} />;
    }
  };

  return (
    <div className="lg:h-full overflow-y-auto">
      <header>
        <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">Webinar programs</h1>
      </header>
      <div className="mt-4">
        <Card className="w-full h-full px-4 mb-2 border border-gray-100">
          <div className="relative">
            <div className="relative bg-white">
              <div className="flex flex-col items-center justify-between p-4 space-y-4 md:flex-row md:space-y-0 md:space-x-4">
                <div className="w-full md:w-1/2 ">
                  <form className="flex items-center">
                    <label htmlFor="simple-search" className="sr-only">
                      Search
                    </label>
                    <div className="relative w-full">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <MagnifyingGlassIcon className="h-4 w-4 text-secondary-500" />
                      </div>
                      <input
                        type="text"
                        disabled
                        id="simple-search"
                        className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 placeholder:italic placeholder:text-gray-300"
                        placeholder="Search (disabled)"
                      />
                    </div>
                  </form>
                </div>
                <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
                  <Link
                    to="create"
                    className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 focus:outline-none"
                  >
                    <PlusIcon className="h-3.5 w-3.5 mr-2 text-white stroke-[3]" />
                    Create program
                  </Link>
                </div>
              </div>
            </div>
            <div className="w-full h-full overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 w-full">
                  <tr className="w-full">
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Program title
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap ">
                      Created by
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap text-center">
                      Asset Management Program
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap ">
                      Created on
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap text-center">
                      Is Active
                    </th>
                    <th scope="col" className="px-6 py-3 text-center">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="">{getTableData()}</tbody>
              </table>
            </div>
            <TablePagination />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default WebinarPrograms;
