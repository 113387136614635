import { DocumentDuplicateIcon, HomeIcon, MagnifyingGlassIcon, UserGroupIcon } from "@heroicons/react/24/outline";

export const userNavigation = [
  { name: "Agent status", href: "/live-agent" },
  { name: "Your profile", href: "/user/my-profile" },
  { name: "Sign out", href: "/auth/signout" },
];

export const sidebarNavigation = [
  { name: "Dashboard", href: "/", icon: HomeIcon },
  { name: "Programs", href: "/programs", icon: DocumentDuplicateIcon },
  { name: "Lead requests", href: "/lead-requests", icon: UserGroupIcon },
  { name: "AI Matching", href: "/search", icon: MagnifyingGlassIcon },
];
