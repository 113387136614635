import transparentLogo from "@assets/logotransparentwhite.png";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { userNavigation } from "./navigationLinks";
import { NavLink } from "react-router-dom";

interface NavbarProps {
  setSidebarOpen: () => void;
}

const Navbar = (props: NavbarProps) => {
  return (
    <div className="sticky lg:hidden top-0 z-30 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 lg:bg-white bg-secondary-800 px-4 shadow-sm sm:gap-x-6 sm:px-6">
      <img src={transparentLogo} className="h-8 w-auto lg:hidden" />
      <div className="flex flex-1 gap-x-4 self-stretch justify-end lg:gap-x-6">
        <div className="flex items-center gap-x-4 lg:gap-x-6">
          {/* Profile dropdown */}
          <Menu as="div" className="relative">
            <Menu.Button className="-m-1.5 flex items-center p-1.5">
              <span className="sr-only">Open user menu</span>
              <img
                className="h-8 w-8 rounded-full bg-gray-50"
                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                alt=""
              />
              <span className="hidden lg:flex lg:items-center">
                <span className="ml-4 text-sm font-semibold leading-6 text-gray-50 lg:text-gray-900" aria-hidden="true">
                  Tom Cook
                </span>
                <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute z-10 mt-2.5 w-32 origin-top-right right-0 rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                {userNavigation.map((item) => (
                  <Menu.Item key={item.name}>
                    <NavLink
                      to={item.href}
                      className={({ isActive }) =>
                        clsx(isActive ? "bg-gray-50" : "", "block px-3 py-1 text-sm leading-6 text-gray-900")
                      }
                    >
                      {item.name}
                    </NavLink>
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>

        <button
          type="button"
          className="-m-2.5 p-2.5 text-primary-300 lg:hidden"
          onClick={() => props.setSidebarOpen()}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6 stroke-2" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

export default Navbar;
