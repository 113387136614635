import { axiosInstance, defaultGetFetcher } from "../../../api/base";
import { Card } from "../../../components/card";
import { z } from "zod";
import { useEffect, useRef, useState } from "react";
import useSWRMutation from "swr/mutation";
import { Switch } from "@headlessui/react";
import clsx from "clsx";
import useSWRImmutable from "swr/immutable";
import { toast } from "react-toastify";

const LiveAgentStatusSchema = z.object({
  meetUrl: z.string().nullable(),
  isActive: z.boolean(),
});

type LiveAgentStatus = z.infer<typeof LiveAgentStatusSchema>;

const executeSetLiveStatusRequest = async (url: string, { arg }: { arg: LiveAgentStatus }) => {
  const response = await axiosInstance.post<LiveAgentStatus>(url, arg);
  return LiveAgentStatusSchema.parse(response.data);
};

const LiveAgentStatusPage = () => {
  const [isUserActive, setIsUserActive] = useState<boolean>(false);
  const meetUrlRef = useRef<HTMLInputElement>(null);

  const { data, isLoading: isInitialLoading } = useSWRImmutable(
    "/api/v2/admin/live-agent/my-status",
    (url: string) => defaultGetFetcher(url),
    { revalidateIfStale: false, revalidateOnFocus: false },
  );

  const { trigger, isMutating } = useSWRMutation("/api/v2/admin/live-agent/my-status", executeSetLiveStatusRequest, {
    revalidate: false,
  });

  const onSaveMyStatusClicked = async () => {
    const data = await trigger({ meetUrl: meetUrlRef.current?.value ?? null, isActive: isUserActive });
    setIsUserActive(data.isActive);
    meetUrlRef.current!.value = data.meetUrl ?? "";
    toast.success("Status saved successfully");
  };

  useEffect(() => {
    console.log("is initial loading changed", isInitialLoading);
    if (!isInitialLoading) {
      meetUrlRef.current!.value = data?.meetUrl ?? "";
      setIsUserActive(data?.isActive ?? false);
    }
  }, [isInitialLoading]);

  return (
    <div className="lg:h-full overflow-y-auto">
      <header>
        <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">Live agent status</h1>
      </header>
      <div className="mt-4">
        <Card className="w-full h-full px-4 mb-2 border border-gray-100">
          <div className="relative">
            <div className="relative bg-white">
              <div className="flex flex-col items-start justify-between p-4 space-y-4 md:flex-row md:space-x-4 divide-y">
                {/* <div className="w-full">{getStatusDisplay()}</div> */}
                <div className="flex flex-col w-full py-4 gap-y-4">
                  <h2 className="text-lg font-semibold text-gray-900">Live Agent Status</h2>
                  <div className="flex flex-grow flex-col">
                    <label htmlFor="email" className="text-sm/6 font-medium text-gray-900">
                      Meet URL
                    </label>
                    <span className="text-sm text-gray-500">
                      This Google Meet URL will be display to potential leads.
                    </span>
                    <div className="mt-2">
                      <input
                        ref={meetUrlRef}
                        id="meetUrl"
                        name="meetUrl"
                        disabled={isMutating || isInitialLoading}
                        type="text"
                        placeholder="meet.google.com/abc-xyz-123"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm/6 disabled:bg-gray-100 disabled:text-gray-500"
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <Switch.Group className="flex items-center justify-between" as="div">
                      <span className="flex flex-grow flex-col">
                        <Switch.Label className="text-sm/6 font-medium text-gray-900">Active</Switch.Label>
                        <Switch.Description className="text-sm text-gray-500">
                          Set your status to active to receive live agent requests.
                        </Switch.Description>
                        <Switch
                          checked={isUserActive}
                          onChange={setIsUserActive}
                          disabled={isMutating || isInitialLoading}
                          className={clsx(
                            "relative inline-flex h-6 w-11 mt-2 items-center rounded-full transition-colors duration-200 ease-in-out cursor-pointer",
                            { "bg-primary-200": !isUserActive },
                            { "bg-primary-600": isUserActive },
                            { "opacity-50 cursor-default": isMutating || isInitialLoading },
                          )}
                        >
                          <span className="sr-only">Enable notifications</span>
                          <span
                            className={`${
                              isUserActive ? "translate-x-6" : "translate-x-1"
                            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                          />
                        </Switch>
                      </span>
                    </Switch.Group>
                  </div>
                  <div className="flex w-full flex-row justify-center">
                    <button
                      disabled={isMutating || isInitialLoading}
                      onClick={onSaveMyStatusClicked}
                      className="text-white bg-primary-700 disabled:bg-gray-300 disabled:text-gray-600 enabled:hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm w-fit px-5 py-2.5 text-center "
                    >
                      {isMutating ? "Saving..." : "Save"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default LiveAgentStatusPage;
