import React from "react";
import useAppStore from "../store/app.store";
import { Navigate } from "react-router-dom";

const ProtectedRoute = (props: React.PropsWithChildren) => {
  const isLoggedIn = useAppStore((state) => state.isLoggedIn);
  if (!isLoggedIn) {
    return <Navigate to="auth/login" replace />;
  }
  return <>{isLoggedIn ? props.children : null}</>;
};
export default ProtectedRoute;
