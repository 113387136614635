import axios from "axios";
import useAppStore from "../store/app.store";

let baseUrl = "http://localhost:8000/";
if (import.meta.env.PROD && import.meta.env.VITE_BASE_API_URL) baseUrl = import.meta.env.VITE_BASE_API_URL;

const UNAUTHORIZED = 401;
export const BASE_URL = baseUrl;

const isUnauthorizedResponse = (status: number, errorData: IErrorResponse | null) => {
  return (
    status === UNAUTHORIZED ||
    (errorData !== null &&
      errorData.error &&
      errorData.error.toLowerCase() == "User must be authenticated.".toLowerCase()) ||
    (errorData !== null &&
      errorData.errorDetails &&
      errorData.errorDetails.toLowerCase() == "User must be authenticated.".toLowerCase())
  );
};

export interface IErrorResponse {
  error: string | null | undefined;
  errorDetails: string | null | undefined;
}

export const handleAxiosError = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    if (error.response?.data) return error.response?.data as IErrorResponse;
  }
  return { error: String(error) } as IErrorResponse;
};

export const axiosInstance = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFTOKEN",
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status, data } = error.response;
    console.log("Axios instance error", status, data);
    if (isUnauthorizedResponse(status, data)) {
      console.log("Setting is logged in to false");
      const { setIsLoggedIn, setAuthData } = useAppStore.getState();
      setIsLoggedIn(false);
      setAuthData(null);
      //TODO: Fix not moving to auth screen
    } else if (status === 400) return Promise.reject(error);
  },
);

export const defaultGetFetcher = async (url: string, queryParams?: Record<string, string | number | undefined>) => {
  //TODO: Fix this error handling
  // try {
  // We return string because data validation is done by zod schema.
  // This is just simple reusable GET fetcher.
  debugger;
  return axiosInstance.get(url, { params: queryParams }).then((response) => response.data);
  // } catch (error: Error | AxiosError | any) {
  //   if (axios.isAxiosError(error)) {
  //     if (error.response) {
  //       // The request was made and the server responded with a status code
  //       // that falls out of the range of 2xx
  //       console.log(error.response);
  //       throw new Error(error.response.data);
  //     } else if (error.request) {
  //       // The request was made but no response was received
  //       // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
  //       // http.ClientRequest in node.js
  //       console.log(error.request);
  //       throw new Error(error.request);
  //     }
  //   }
  //   console.log(error);
  //   throw new Error("There has been error fetching data. Please try again.");
  // }
};
